/* You can add global styles to this file, and also import other style files */
$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
$hamburger-layer-width: 25px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: #9b9ea2 !default;
$hamburger-layer-border-radius: 4px !default;
$hamburger-hover-opacity: 0.7 !default;
//@import "~bootstrap/scss/bootstrap";
// @import '~font-awesome/scss/font-awesome';
// @import '~hamburgers/_sass/hamburgers/hamburgers';
//@import "~ngx-toastr/toastr-bs4-alert";
@import "./assets/fontawesome6.2.1/scss/fontawesome.scss";
@import "./assets/fontawesome6.2.1/scss/solid.scss";
@import "./assets/fontawesome6.2.1/scss/regular.scss";
//@import "ej2/material.scss";
//@import "assets/partials/bootstrap4";
@import "assets/arcarius-admin";